import request from '../utils/request'
import config from './config'

/**
 * 机构信息
 */

// 行政区划
export function selectViewListServiceFacilities(data) {
  return request({
    url: `${config.CIVIL_HOST}/pensionServiceFacilities/selectViewListServiceFacilities`,
    method: 'post',
    data,
  })
}

// 数据字典
export function getDictList(params) {
  return request({
    url: `${config.CIVIL_HOST}/pensionServiceFacilities/backSearchDict`,
    method: 'get',
    params,
  })
}

// 下载机构备案模板
export function templateDownload(data) {
  return request({
    url: `${config.CIVIL_HOST}/pensionServiceFacilities/downloadTemplate`,
    method: 'get',
    data,
    responseType: 'blob',
  })
}

// 机构备案详情
export function selectServiceFacilitiesDetail(params) {
  return request({
    url: `${config.CIVIL_HOST}/pensionServiceFacilities/selectServiceFacilitiesDetail`,
    method: 'get',
    params,
  })
}
