<template>
  <el-card class="box-card">
    <div class="search-table-box">
      <div>
        <el-form ref="form" :inline="true" :model="queryParams" label-width="80px">
          <el-form-item label="行政区划">
            <el-cascader
              class="w300"
              :props="props"
              placeholder="请选择行政区划"
              v-model="queryParams.administrativeDivision"
              @change="change"
              clearable
            ></el-cascader>
          </el-form-item>
          <el-form-item label="机构性质">
            <el-select class="w300" v-model="queryParams.institutionalNature" placeholder="请选择机构性质" clearable>
              <el-option
                v-for="item in organizationAttributeCategoryOption"
                :label="item"
                :value="item"
                :key="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="机构形式">
            <el-select class="w300" v-model="queryParams.institutionalForm" placeholder="请选择机构形式" clearable>
              <el-option
                v-for="item in organizationTypeOption"
                :label="item"
                :value="item"
                :key="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="关键字">
            <el-input
              style="width: 300px"
              clearable
              v-model="queryParams.searchName"
              placeholder="请输入关键字"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="searchList">查询</el-button>
          </el-form-item>
        </el-form>
        <div class="btn">
          <el-button type="primary" @click="downTemp">模板下载</el-button>
          <el-button type="primary" @click="uploadVisible = true">导入</el-button>
        </div>
      </div>
      <el-table v-loading="loading" style="width: 100%; margin-top: 10px" :data="tableData" border :header-cell-style="headerCellStyle">
        <template slot="empty">
          <IsEmpty />
        </template>
        <el-table-column type="index" label="序号" width="50" align="center" />
        <el-table-column prop="organizationName" label="机构名称" align="center" />
        <el-table-column prop="socialCreditCode" label="统一社会信用代码" show-overflow-tooltip align="center" />
        <el-table-column prop="administrativeDivision" label="行政区划" show-overflow-tooltip align="center" />
        <el-table-column prop="institutionalNature" label="机构性质" show-overflow-tooltip align="center" />
        <el-table-column prop="institutionalForm" label="机构形式" show-overflow-tooltip align="center" />
        <el-table-column prop="contactName" label="联系人" align="center" />
        <el-table-column prop="contactTelephone" label="联系电话" align="center" />
        <el-table-column prop="mailingAddress" label="通讯地址" align="center" />
        <el-table-column prop="pop" label="操作" width="250" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" @click="toDetail(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
      <!-- 上传 -->
      <UploadFile url="/pensionServiceFacilities/readExcel" :uploadVisible.sync="uploadVisible" @updateList="searchList" />
    </div>
  </el-card>
</template>

<script>
  import { administrativeDivision } from '@/api/institutionalManage'
  import { selectViewListServiceFacilities, getDictList, templateDownload } from "@/api/elderlyCareFacilities"
  import { downloadStream } from '@/utils'
  import UploadFile from '@/components/uploadFile'
  export default {
    name: 'index',
    components: {
      UploadFile,
    },
    data() {
      return {
        areaList: [], // 行政区域
        props: {
          lazy: true,
          checkStrictly: true,
          lazyLoad: this.lazyLoad,
        },
        tableData: [
          {
            accountNumber: '111',
            name: '111',
            idNumber: '111',
            contactPhone: '11',
            departmentName: '',
            status: 1,
          },
        ],
        signStatus: '',
        total: 0,
        loading: false,
        organizationTypeOption: [],
        organizationAttributeCategoryOption: [],
        queryParams: {
          //查询参数
          pageNum: 1,
          pageSize: 10,
          administrativeDivision: '', // 行政区划
          institutionalNature: '', // 机构性质
          institutionalForm: '', // 机构形式
          searchName: '', // 关键字
        },
        uploadVisible: false,
      }
    },
    mounted() {
      this.getOption()
      this.getList()
    },
    methods: {
      // 获取行政区划
      async lazyLoad(node, resolve) {
        const { data } = node
        let res
        if (data) {
          res = await administrativeDivision({ organizationId: data.id })
        } else {
          res = await administrativeDivision()
        }
        const nodes = res.data.map((item) => ({
          value: item.organizationName,
          label: item.organizationName,
          id: item.organizationId,
        }))
        resolve(nodes)
      },
      change(value) {
        console.log(value, 'change')
      },
      // 下载模板
      async downTemp() {
        const res = await templateDownload()
        downloadStream(res, '社区养老服务设施.xlsx')
      },
      // 获取数据字典-- InstitutionalNature：机构性质 InstitutionalForm:机构形式
      async getOption() {
        const classification = await getDictList({ searchType: 'InstitutionalForm' })
        const attribute = await getDictList({ searchType: 'InstitutionalNature' })
        this.organizationTypeOption = classification.data
        this.organizationAttributeCategoryOption = attribute.data
      },
      //分页序号
      count(index) {
        return (this.queryParams.pageNum - 1) * this.queryParams.pageSize + index + 1
      },
      toDetail(row) {
        this.$router.push({ path: '/institutionalInformation/elderlyCareFacilities/elderlyCareFacilitiesDetail', query: { id: row.id } })
      },
      searchList() {
        this.queryParams.pageNum = 1
        this.getList()
      },
      handleSizeChange(pageSize) {
        this.queryParams.pageSize = pageSize
        this.queryParams.pageNum = 1
        this.getList()
      },
      handleCurrentChange(pageNum) {
        this.queryParams.pageNum = pageNum
        this.getList()
      },
      getList() {
        this.loading = true
        var params = Object.assign({ ...this.queryParams })
        params.administrativeDivision =
          params.administrativeDivision && params.administrativeDivision.length > 0
            ? params.administrativeDivision[params.administrativeDivision.length - 1]
            : ''
        selectViewListServiceFacilities({ ...params })
          .then((res) => {
            this.tableData = res.data.records
            this.total = res.data.total
            this.loading = false
          })
          .catch(() => {
            this.tableData = []
            this.total = 0
            this.loading = false
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .box-card {
    min-height: calc(100% - 10px);
    .btn {
      text-align: right;
    }
  }
</style>
